'use strict';

/* Polyfills */
if (typeof NodeList.prototype.forEach === "undefined") {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof HTMLCollection.prototype.forEach === "undefined") {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

/* App Class */
class App {

	constructor(){
		this.nav = new Nav();
		this.headmotion = new Headmotion('.headmotion', {
			style: 'rain',
			delay: 500,
			delaydir: 'random',
		});
		this.sendmodal = new Modal(document.querySelector('.send-modal'), window.location.hash.indexOf('send') > 0)
		this.sendform = new Form(document.querySelector('.send-form'))
	}

	init(){
		this.nav.init();
		if (this.sendform.container) this.sendform.init()
		if (this.sendmodal.container) this.sendmodal.init()
		window.addEventListener('resize', e => {
			this.nav.close()
			this.sendmodal.close()
		})
	}

}

/*Heading Animation Class*/
class Headmotion{

	constructor(selector,opt={}){
		this.container = document.querySelector(selector);
		this.opt = {...Headmotion.defaults(),...opt};
		this.letters = [];
		if (this.container) this.init();
	}

	static defaults(){
		return {
			style: 'default',
			wordtag: 'span',
			lettertag: 'span',
			delay: 70,
			delaydir: 'right'
		};
	}

	static random(min,max){
		return Math.random() * (max - min) + min;
	}

	static floor(min,max){
		return Math.floor(this.random(min,max));
	}

	init(){
		this.container.classList.add('headmotion-inited');
		this.container.innerHTML = this.wordCutter();
		this.letters = Array.prototype.slice.call( this.container.querySelectorAll(this.opt.lettertag) );
		if (this.letters.length) this.animate();
	}

	wordCutter(){
		let html = '';
		this.container.textContent.split(' ').map( word => html+=this.wordTemplate(word) );
		return html;
	}

	charCutter(word){
		
		let html = '';
		word.split('').map( char => html+=this.letterTemplate(char) );
		return html;
	}

	wordTemplate(word){
		return `<${this.opt.wordtag} class='headmotion-word'>${this.charCutter(word)}</${this.opt.wordtag}>`;
	}

	letterTemplate(char){
		return `<${this.opt.lettertag} class='headmotion-letter'>${char}</${this.opt.lettertag}>`;
	}

	animate(){
		this.container.classList.add(`headmotion-animate__${this.opt.style}`);
		this.letters.forEach( (letter,index) => {
			let delay = this.opt.delay;
			switch (this.opt.delaydir){
				case 'right': 
					delay = Math.abs(parseInt(this.opt.delay))*(index+1);
					break;
				case 'random':
					delay = Headmotion.floor(10, parseInt(this.opt.delay));
					break;
				default: 
					break;
			}
			letter.style.animationDelay = `${delay}ms`;
		} );
	}

	update(){
		this.container.classList.remove(`headmotion-animate__${this.opt.style}`);
		setTimeout(() => this.animate(), 100);
	}

}

/* Nav Class */
class Nav {

	constructor(){
		this.controlSelector = '[data-action="nav"]'
	}

	init(){
		this.control = document.querySelector(this.controlSelector)
		if (this.control) this.control .addEventListener('click', e => {
			e.preventDefault()
			document.body.classList.toggle('nav-open')
		})
	}

	show(){
		document.body.classList.add('nav-open')
	}

	close(){
		document.body.classList.remove('nav-open')
	}

}

/* Form Class */
class Form {

	constructor(container=false){
		this.container = container
		this.controls = []
		this.sender = false
	}

	init(){
		const controls = this.container.elements
		if (controls) controls.forEach(control => {
			switch(control.type){
				case 'text' : this.controls.push(new Control(control)); break
				case 'button': this.sender = control; break;
				default : break;
			}
			if (this.sender) this.sender.addEventListener('click', e => {
				e.preventDefault()
				this.send()
			})
		})
		this.container.addEventListener('submit', e => {
			e.preventDefault()
			this.send()
		})
	}

	data(){
		let data = "?"
		this.controls.forEach( (control, index) => {
			data += `${index ? '&':''}${control.name}${control.value ? '='+control.value : ''}`
		} );
		return data
	}

	send(){
		if (this.valid()) {
			let req = new XMLHttpRequest()
			const data = this.data()
			const tableURL = 'https://script.google.com/macros/s/AKfycbwxBbClgggtvgnLqkDQmPrHiseclZU0mF3NeXJWTlXRu5_Rn8et/exec'
			req.open('GET', tableURL + data, !0)
			req.onreadystatechange = () => {
				this.success()
				this.clear()
			};
			req.send(null)
			return true;
		} else {
			return false;
		}
	}

	success(){
		const dialog = document.querySelector('.send-modal .dialog') || this.container.parentNode
		if (dialog) dialog.innerHTML = `
			<div class="dialog-success">
				<h2>Заявка успешно отправлена</h2>
				<p>Мы свяжемся с вами в самое ближайшее время</p>
			</div>
		`
	}

	clear(){
		if (this.container) this.container.reset()
	}

	valid(){
		let results = [], startValid = undefined
		this.controls.forEach(control => {
			if (control.valid) {
				const valid = control.valid()
				results.push(valid)
				if (valid.valid === false) startValid = false
			}
		})
		if (startValid === undefined || startValid === true) {
			return true
		} else {
			results.forEach(result => {
				if (result.valid === false && result.group) {
					let error = result.group.querySelector('em')
					if (!error) {
						error = document.createElement('em')
						result.group.appendChild(error)
					}
					error.textContent = result.error
				}
			})
			return false
		}
	}

}

/*Control Class */
class Control {

	constructor(el=false){
		this.el = el
		this.pattern = false
		this.init()
	}

	init(){
		if (this.el) {
			const group = this.el.parentNode || false
			const pattern = this.el.dataset.pattern || false
			const req = this.el.required || false
			if (pattern && group) this.validate = new Validate(pattern, group, req)
		}
	}

	valid(){
		return this.validate ? this.validate.result(this.el.value.trim()) : {valid: true}
	}

	get name(){
		return this.el.name
	}

	get value(){
		return this.el.value
	}

}

/* Validate Class */
class Validate {

	constructor(pattern=false, group=false, required=false){
		this.pattern = pattern
		this.group = group
		this.required = required
		this.rule = false
		this.init()
	}

	init(){
		if (this.pattern) {
			switch(this.pattern){
				case 'name' : this.rule = this.nameRule; break;
				case 'phone' : this.rule = this.phoneRule; break;
				default : break;
			}
		}
	}

	nameRule(value){
		let error = '', valid = true, mask = new RegExp(/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/gui)
		if (!value && this.required) {
			error = "Вы не заполнили обязательное поле"
			valid = false
		}
		if (value) {
			if (value.length > 30) {
				error = "Значение не должно превышать 30 символов"
				valid = false
			}
			if (value.length < 2) {
				error = "Значение не может быть короче 2 символов"
				valid = false
			}
			if (!mask.test(value)) {
				error = "Поле не может содержать цифры и знаки пунктуации"
				valid = false
			}
		}
		return {valid, error, pattern: this.pattern, group: this.group}
	}

	phoneRule(value){
		let error = '', valid = true, mask = new RegExp(/^\d*?$/g)
		if (!value && this.required) {
			error = "Вы не заполнили обязательное поле"
			valid = false
		}
		if (value) {
			if (value.length > 11) {
				error = "Значение не должно превышать 11 символов"
				valid = false
			}
			if (value.length < 6) {
				error = "Значение не может быть короче 6 символов"
				valid = false
			}
			if (!mask.test(value)) {
				error = "Поле может содержать только цифры"
				valid = false
			}
		}
		return {valid, error, pattern: this.pattern, group: this.group}
	}

	result(value){
		return this.rule ? this.rule(value) : {valid: true}
	}

}

/* Modal Class */
class Modal {

	constructor(container=false, open=false){
		this.container = container
		this.crossSelector = '[data-action="close"]'
		this.initiatorSelector = '[data-action="send"]'
		this.open = open
	}

	init(){
		this.cross = this.container.querySelector(this.crossSelector)
		if (this.cross) this.cross.addEventListener('click', e => {
			e.preventDefault()
			this.close()
		})
		this.initiators = document.querySelectorAll(this.initiatorSelector)
		if (this.initiators.length) this.initiators.forEach(initiator => {
			initiator.addEventListener('click', e => {
				e.preventDefault()
				this.show()
			})
		})
		if (this.open) this.show()
	}

	show(){
		this.container.classList.add('open')
		document.body.classList.add('modal-open')
	}

	close(){
		this.container.classList.remove('open')
		document.body.classList.remove('modal-open')
	}

}

const app = new App();
window.addEventListener('DOMContentLoaded', () => app.init());